import React, {useState, useCallback, useRef, useEffect} from "react";
import AudioAnalyzer from "./components/AudioAnalyzer";
import Visualizer from "./components/Visualizer2";
import Timeline from "./components/Timeline";
import GeometryDashVisualizer from "./components/GeometryDashVisualizer";
import "./App.css";

const App = () => {
    const [audioData, setAudioData] = useState([]);
    const [scenes, setScenes] = useState([]);

    const handleAudioProcess = useCallback((data) => {
        setAudioData(data);
    }, []);

    const handleScenesDetected = (detectedScenes) => {
        setScenes(detectedScenes);
    };

    useEffect(() => {
        const preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        // document.addEventListener('touchstart', preventDefault, { passive: false });
        document.addEventListener('touchmove', preventDefault, { passive: false });

        return () => {
            // document.removeEventListener('touchstart', preventDefault);
            document.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    return (
        <div>
            {/*<Visualizer audioData={audioData} scenes={scenes} />*/}
            {/*<Timeline scenes={scenes} />*/}
            <GeometryDashVisualizer audioData={audioData} scenes={scenes} />
            <AudioAnalyzer
                onAudioProcess={handleAudioProcess}
                onScenesDetected={handleScenesDetected}
            />
        </div>
    );
};

export default App;